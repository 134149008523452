import React from "react";
import Layout from "../components/Layout";
import BlogList from "../components/BlogList";
import "../theme.css";
import { graphql, useStaticQuery } from "gatsby";
import ImageLink from "../components/ImageLink";
import * as styles from "../components/BlogList.module.css";
import BlogPost from "../components/BlogPost";

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { contentKey: { eq: "blog" } } }
        limit: 10
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              categories
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                publicURL
              }
              date(formatString: "MMM D, YYYY")
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="row remove-site-content-margin">
        <div className="col-md-6">
          <div className="card border-0 mb-4 box-shadow">
            <ImageLink
              key={data.allMarkdownRemark.edges[0].node.id}
              className={styles.topImage}
              title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
              image={data.allMarkdownRemark.edges[0].node.frontmatter.image}
              slug={data.allMarkdownRemark.edges[0].node.fields.slug}
            />
            <div className="card-body px-0 pb-0 d-flex flex-column align-items-start">
              <BlogPost
                key={data.allMarkdownRemark.edges[0].node.id}
                className={styles.topTitle}
                title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
                categories={
                  data.allMarkdownRemark.edges[0].node.frontmatter.categories
                }
                date={data.allMarkdownRemark.edges[0].node.frontmatter.date}
                slug={data.allMarkdownRemark.edges[0].node.fields.slug}
                excerpt={data.allMarkdownRemark.edges[0].node.excerpt}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-12">
          {[1, 2, 3].map((i) => (
            <div
              className="mb-3 d-flex align-items-center"
              key={data.allMarkdownRemark.edges[i].node.id}
            >
              <div className="col-md-4 col-sm-4 col-4">
                <ImageLink
                  key={data.allMarkdownRemark.edges[i].node.id}
                  className={styles.latestImage}
                  title={data.allMarkdownRemark.edges[i].node.frontmatter.image}
                  image={data.allMarkdownRemark.edges[i].node.frontmatter.image}
                  slug={data.allMarkdownRemark.edges[i].node.fields.slug}
                />
              </div>
              <BlogPost
                key={data.allMarkdownRemark.edges[i].node.id}
                className={styles.latestTitle}
                title={data.allMarkdownRemark.edges[i].node.frontmatter.title}
                categories={
                  data.allMarkdownRemark.edges[i].node.frontmatter.categories
                }
                date={data.allMarkdownRemark.edges[i].node.frontmatter.date}
                slug={data.allMarkdownRemark.edges[i].node.fields.slug}
              />
            </div>
          ))}
        </div>
      </div>
      <BlogList
        title="Other Posts"
        edges={data.allMarkdownRemark.edges.slice(4, 10)}
      />
    </Layout>
  );
}
