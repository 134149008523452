import React from "react";
import TitleLink from "./TitleLink";
import Excerpt from "./Excerpt";
import CategoryList from "./CategoryList";

export default function BlogPost({
  className,
  title,
  categories,
  date,
  slug,
  excerpt,
}) {
  return (
    <div>
      <TitleLink className={className} title={title} slug={slug} />
      {excerpt && <Excerpt excerpt={excerpt} />}
      <div>
        <CategoryList categories={categories} />
        {date && <small className="text-muted">{date}</small>}
      </div>
    </div>
  );
}
