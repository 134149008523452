import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import * as styles from "./FeaturedList.module.css";
import BlogPost from "./BlogPost";

export default function FeaturedList() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: {
            contentKey: { eq: "blog" }
            tags: { in: ["featured"] }
          }
        }
        limit: 10
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              categories
              date(formatString: "MMM D, YYYY")
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);

  return (
    <div className="sticky-top sticky-top-offset">
      <h4 className="font-weight-bold spanborder">
        <span>Featured</span>
      </h4>
      <ol className="list-featured">
        {data.allMarkdownRemark.edges.map((edge) => (
          <li className="mb-4" key={edge.node.id}>
            <span>
              <BlogPost
                key={edge.node.id}
                className={styles.featuredTitle}
                title={edge.node.frontmatter.title}
                categories={edge.node.frontmatter.categories}
                slug={edge.node.fields.slug}
              />
            </span>
          </li>
        ))}
      </ol>
    </div>
  );
}
