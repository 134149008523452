import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function ImageLink({ className, title, image, slug }) {
  return (
    <Link to={slug}>
      {image.childImageSharp ? (
        <GatsbyImage
          id={slug}
          className={className}
          image={getImage(image)}
          alt={title}
        />
      ) : (
        <img src={image.publicURL} alt={title} />
      )}
    </Link>
  );
}
