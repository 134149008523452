import React from "react";
import { Link } from "gatsby";

export default function TitleLink({ className, title, slug }) {
  return (
    <div id="title" className={className}>
      <Link className="text-dark" to={slug}>
        {title}
      </Link>
    </div>
  );
}
