import React from "react";
import { Link } from "gatsby";
import * as styles from "./CategoryList.module.css";

export default function CategoryList({ categories }) {
  return (
    <small className={styles.categoryList}>
      In{" "}
      {categories.map((category, index) => (
        <span key={category}>
          {index !== 0 && <span className="sep">, </span>}
          <Link
            className="text-capitalize text-muted"
            to={`/categories/${category}`}
          >
            {category}
          </Link>
        </span>
      ))}
    </small>
  );
}
