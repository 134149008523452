import React from "react";
import * as styles from "./BlogList.module.css";
import ImageLink from "./ImageLink";
import BlogPost from "./BlogPost";
import FeaturedList from "./FeaturedList";

export default function BlogList({ title, edges }) {
  return (
    <div className="row mt-3">
      <div className="col-md-8 main-loop">
        <h4 className="font-weight-bold spanborder">
          <span>{title}</span>
        </h4>
        {edges.map((edge) => (
          <div
            className="mb-5 d-flex justify-content-between main-loop-card"
            key={edge.node.id}
          >
            <BlogPost
              key={edge.node.id}
              className={styles.postTitle}
              title={edge.node.frontmatter.title}
              categories={edge.node.frontmatter.categories}
              date={edge.node.frontmatter.date}
              slug={edge.node.fields.slug}
              excerpt={edge.node.excerpt}
            />
            <div className="col-md-4 col-sm-4 col-4">
              <ImageLink
                key={edge.node.id}
                className={styles.postImage}
                title={edge.node.frontmatter.image}
                image={edge.node.frontmatter.image}
                slug={edge.node.fields.slug}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="col-md-4">
        <FeaturedList />
      </div>
    </div>
  );
}
